/**
 * Validates whether a given string represents a valid email address.
 *
 * @param {string} email - The input string to validate.
 * @returns {boolean} True if the string is a valid email address, false otherwise.
 */
const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
    return emailRegex.test(String(email).toLowerCase());
};

/**
 * Validates whether a given string represents a valid Indian mobile number.
 *
 * @param {string} mobile - The input string to validate.
 * @returns {boolean} True if the string is a valid mobile number, false otherwise.
 */
const validateMobileNumber = (mobile) => {
    const mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;
    return mobileRegex.test(mobile);
};


/**
 * Validates whether a string contains only alphabetic characters (including spaces).
 *
 * @param {string} stringValue - The input string to validate.
 * @returns {boolean} True if the string contains only alphabetic characters, false otherwise.
 */

const validateAlphabetString = (stringValue) => {
    const alphabetRegex = /^[A-Za-z\s]+$/;
    return alphabetRegex.test(stringValue);
}


/**
 * Validates whether a given string contains only specific characters (alphanumeric, spaces, hyphens, underscores, periods, and commas).
 *
 * @param {string} value - The input string to validate.
 * @returns {boolean} True if the string contains only valid characters, false otherwise.
 */
const checkForSpecialCharacters = (value) => {
    const specialCharactersRegex = /^[a-zA-Z0-9\s\-_.,]+$/;
    return specialCharactersRegex.test(value);
};

/**
 * Validates whether a given string contains only specific characters (numeric).
 *
 * @param {string} value - The input string to validate.
 * @returns {boolean} True if the string contains only valid characters, false otherwise.
 */
const validateZipCode = (value) =>{
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(value) && value.length === 6;
}

export { validateEmail, validateMobileNumber, validateAlphabetString, checkForSpecialCharacters,validateZipCode };
