import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import userService from "../../../services/api-services/userServices";
import { trimObjectValues } from "../../../utils/JSutils";

const ResendRegistrationOtp = ({ registrationDetails, resetErrors }) => {
  const [timer, setTimer] = useState(60);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timer);
    }
  }, [timer]);

  const resendOtp = async () => {
    const userData = trimObjectValues(registrationDetails);
    try {
      setIsLoading(true);
      const response = await userService.resendRegisterOTP(userData);
      const { detail, data, message, title } = response;
      if (title === apiStatus.success) {
        resetErrors();
        setTimer(60);
        // toast.success(detail);
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="resend-otp-wrapper">
      {timer > 0 ? (
        <>
          <label className="form-label">Resend OTP in </label> 00:
          {timer < 10 ? `0${timer}` : timer}
        </>
      ) : (
        <button className="resend-otp-btn" onClick={resendOtp}>
          {isLoading && <Spinner animation="border" size="sm" />} Resend OTP
        </button>
      )}
    </div>
  );
};

export default ResendRegistrationOtp;
