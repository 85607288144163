import React from "react";

const SpinnerLoader = () => {
  return (
    <div className="loader-container bg-custom-white h-100  ">
      <div className="spinner-border" role="status"></div>
    </div>
  );
};

export default SpinnerLoader;
