import React, { useEffect, useState } from "react";
import LeftDesign from "../common/LeftDesign/LeftDesign";
import { Outlet, useLocation } from "react-router-dom";
import userService from "../../../services/api-services/userServices";
import apiStatus from "../../../utils/apiResponseHandler";

const MemberVerification = () => {
  const [memberDetails, setMemberDetails] = useState({});

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const qParam = queryParams.get("q");
    getMemberDetails(qParam);
  }, []);

  const getMemberDetails = async (id) => {
    try {
      const response = await userService.verifyMemberDetails(id);
      const { title, data, detail } = response;
      if (detail === apiStatus.success) {
        setMemberDetails(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="login-page-container">
      <div className="row h-100 m-0">
        <div className="col-md-5 col-sm-12 p-2 p-md-4">
          <LeftDesign />
        </div>
        <div className="col-md-7 col-sm-12">
          <div className="login-input-wrapper-container">
            <div className="login-input-wrapper">
              <Outlet context={{ memberDetails }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberVerification;
