import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";
import { trimObjectValues } from "../../../utils/JSutils";
import Spinner from "react-bootstrap/Spinner";
import userService from "../../../services/api-services/userServices";
import { toast } from "react-toastify";
import { emailRegex, mobileRegex } from "../../../utils/Regex";
import apiStatus from "../../../utils/apiResponseHandler";

const SendOtpForm = () => {
  let navigate = useNavigate();
  const { loginDetails, setLoginDetails } = useOutletContext();
  const [errors, setErrors] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    let data = { ...loginDetails };
    data[name] = value;
    setLoginDetails(data);
  };

  const validateForm = () => {
    let { emailOrMobile } = {
      ...trimObjectValues(loginDetails),
    };
    let errorList = {};
    let valid = true;

    if (emailOrMobile.length === 0) {
      errorList.emailOrMobile = "Please enter email address or mobile number";
      valid = false;
    }
    if (emailRegex.test(emailOrMobile) || mobileRegex.test(emailOrMobile)) {
      valid = true;
    } else {
      errorList.emailOrMobile =
        "Please enter valid email address or mobile number";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const submitClick = (e) => {
    e.preventDefault();
    if (validateForm() && !isLoading) {
      userLogin();
    }
  };

  const userLogin = async () => {
    try {
      let response = {};
      setIsLoading(true);
      let { emailOrMobile } = trimObjectValues(loginDetails);

      if (emailRegex.test(emailOrMobile)) {
        setLoginDetails({ ...loginDetails, loginType: "email id" });
        response = await userService.loginByEmail(loginDetails.emailOrMobile);
      }

      if (mobileRegex.test(emailOrMobile)) {
        setLoginDetails({ ...loginDetails, loginType: "mobile number" });
        response = await userService.loginByMobile(loginDetails.emailOrMobile);
      }

      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        navigate("/login/verify-otp");
      } else {
        setErrors({ emailOrMobile: detail?.replace(/'/g, "") });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="text-center">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3">Sign In to Continue</h3>
        <p className="description mb-5">
          Enter your details to proceed further
        </p>
      </div>

      <form onSubmit={submitClick} className="login-input-wrapper">
        <div
          className={`error-boundary ${errors.emailOrMobile ? "error" : ""}`}
        >
          <label className="form-label">Email Address or Mobile Number *</label>
          <div className="mb-4">
            <input
              type="text"
              className={`form-control input-one-line`}
              name="emailOrMobile"
              onChange={inputChangeHandler}
              value={loginDetails.emailOrMobile}
            />
            {errors.emailOrMobile && (
              <div className="input-error-text">{errors.emailOrMobile}</div>
            )}
          </div>
          {apiMessage && (
            <p className="mb-5 error-info">
              The entered email is is not registered with Boozno.com. Please
              contact admin.
            </p>
          )}
        </div>

        <button type="submit" className="btn btn-primary w-100 mb-4">
          {isLoading && <Spinner animation="border" size="sm" />}
          <span className="ms-1">Send OTP</span>
        </button>

        <div className="txt-smi-dark text-center sign-up-text">
          Don't have an account?{" "}
          <Link to="/register/personal" className="text-decoration-none">
            <span className="txt-primary">Sign up</span>
          </Link>
        </div>
        {/* <InactiveAccountModal /> */}
      </form>
    </div>
  );
};

export default SendOtpForm;
