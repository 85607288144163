import React from "react";
import { Business } from "../../../assets/SvgIcons";
import { useNavigate, useOutletContext } from "react-router-dom";

const RegistrationStepper = ({
  personal = "blank",
  companyDetails = "blank",
  otpVerify = "blank",
}) => {
  const { registrationDetails, setRegistrationDetails } = useOutletContext();
  const navigate = useNavigate();
  const {
    firstName,
    middleName,
    lastName,
    mobileNumber,
    countryCode,
    gstNumber,
    companyName,
    entityName,
    addressLineOne,
    addressLineTwo,
    zipCode,
    emailId,
    countryId,
    cityId,
    stateId,
  } = registrationDetails;

  const checkCompanyDetails =
    gstNumber !== "" &&
    companyName !== "" &&
    entityName !== "" &&
    addressLineOne !== "" &&
    zipCode !== "" &&
    !!countryId &&
    !!cityId &&
    !!stateId;

  const checkPersonal =
    firstName !== "" &&
    lastName !== "" &&
    mobileNumber !== "" &&
    emailId !== "";

  return (
    <div className="stepper-progress-bar">
      <div className="stepper-icon">
        <div
          className={`stepper-icon-container ${personal}`}
          role="button"
          onClick={() => checkPersonal && navigate("/register/personal")}
        >
          <div className="icon">
            <Business color={personal === "blank" ? "#D8D8D8" : ""} />
          </div>
          <div className="title">Personal Details</div>
        </div>
        <div className="line" />
        <div
          className={`stepper-icon-container ${companyDetails}`}
          role="button"
          onClick={() => checkPersonal && navigate("/register/company-details")}
        >
          <div className="icon">
            <Business color={companyDetails === "blank" ? "#D8D8D8" : ""} />
          </div>
          <div className="title">Company Details</div>
        </div>

        <div className="line" />
        <div
          className={`stepper-icon-container ${otpVerify}`}
          role="button"
          onClick={() =>
            checkPersonal && checkCompanyDetails && navigate("/register/verify-otp")
          }
        >
          <div className="icon">
            <Business color={otpVerify === "blank" ? "#D8D8D8" : ""} />
          </div>
          <div className="title">Verification</div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationStepper;
