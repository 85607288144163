import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import "./CustomDropdown.scss";
import { useEffect, useRef } from "react";

const CustomDropdown = ({
  dataSource,
  value,
  isLoading,
  customCss,
  ...props
}) => {
  const dropdownRef = useRef(null);

  if (isLoading) {
    return <></>;
  } else {
    return (
      <DropDownListComponent
        key={props.name}
        fields={{ text: "text", value: "value" }}
        {...props}
        value={!value ? null : value}
        valueTemplate={ValueTemplate}
        dataSource={dataSource?.sort((a, b) => a.text?.localeCompare(b.text))}
        itemTemplate={ItemTemplate}
        noRecordsTemplate={NoRecordTemplate}
        cssClass={`custom-dropdown ${customCss}`}
        filtering={(e) => {
          const query = e.text.toLowerCase();
          return dataSource.filter((option) =>
            option.text.toLowerCase().includes(query)
          );
        }}
      />
    );
  }
};

export default CustomDropdown;

const ValueTemplate = (data) => {
  return (
    <div title={data.text} className="text-truncate">
      {data.text}
    </div>
  );
};

const ItemTemplate = (data) => {
  return <span>{data.text}</span>;
};

const NoRecordTemplate = () => {
  return <div className="p-2">No Records</div>;
};
