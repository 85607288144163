import React from "react";
import { Modal } from "react-bootstrap";
import img from "../../../assets/img/popup-tick-img.png";

const AccountCreatedModal = ({ show, setShowModal }) => {
  return (
    <Modal show={show} onHide={() => setShowModal(false)} centered fullscreen className="p-0" >
      <Modal.Body className="d-flex justify-content-center align-items-center text-center">
        <div className="p-5">
          <img src={img} alt="account-created" className="mb-3" />
          <h4 className="fw-bold mb-3">Account Created</h4>
          <p className="description mb-3 text-center">
            Your account has been successfully created. Our team will get in
            touch with<br /> you to activate your account
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccountCreatedModal;
