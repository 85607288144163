import axiosInstance from "../axiosInstance";
import { endPointsUrls } from "../endPointsUrls";

const userService = {
  loginByEmail: (email) => {
    return axiosInstance.post(endPointsUrls.loginByEmail, email);
  },
  loginByMobile: (mobile) => {
    return axiosInstance.post(endPointsUrls.loginByMobile, mobile);
  },
  verifyEmailOTP: (otpDetails) => {
    return axiosInstance.post(endPointsUrls.verifyEmailOTP, otpDetails);
  },
  verifyMobileOTP: (otpDetails) => {
    return axiosInstance.post(endPointsUrls.verifyMobileOTP, otpDetails);
  },
  validatePersonalInfo: (userData) => {
    return axiosInstance.post(endPointsUrls.validatePersonalInfo, userData);
  },
  registerUser: (userData) => {
    return axiosInstance.post(endPointsUrls.registerUser, userData);
  },
  confirmRegistration: (otpDetails) => {
    return axiosInstance.post(endPointsUrls.confirmRegistration, otpDetails);
  },
  resendRegisterOTP: (userData) => {
    return axiosInstance.post(endPointsUrls.resendRegistrationOTP, userData);
  },
  getProfileDetails: () => {
    return axiosInstance.get(endPointsUrls.getProfile);
  },
  upLoadProfileImage: (payload) => {
    return axiosInstance.post(endPointsUrls.upLoadProfileImage, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getSubscriptionDetails: () => {
    return axiosInstance.get(endPointsUrls.subscriptionDetails);
  },

  getUserPermissions: () => {
    return axiosInstance.get(endPointsUrls.getUserPermissions);
  },

  //member verification

  verifyMemberDetails: (guid) => {
    return axiosInstance.get(
      endPointsUrls.verifyMember + `?GuidUserId=${guid}`
    );
  },

  sendMemberVerificationOtp: (payload) => {
    return axiosInstance.post(endPointsUrls.sendMemberOtp, payload);
  },
};

export default userService;
