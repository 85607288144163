import React, { useState } from "react";
import userService from "../../../services/api-services/userServices";
import { useNavigate, useOutletContext } from "react-router-dom";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import OtpInputs from "../common/InputOtp/OtpInputs";
import VerificationSuccessModal from "./VerificationSuccessModal";
import ResendRegistrationOtp from "../registration/ResendRegistrationOtp";

const MemberVerificationOtp = () => {
  const navigate = useNavigate();
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [emailOTPNumber, setEmailOTP] = useState("");
  const [mobileOTPNumber, setMobileOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { memberDetails } = useOutletContext();

  const replaceEmailChars = (email = "") => {
    let [first, last] = email.split("@");
    if (first.length > 4) {
      const center = Math.floor((first.length - 4) / 2);
      return (
        first.substring(0, center) +
        "****" +
        first.substring(center + 4) +
        "@" +
        last
      );
    } else return email || "user.email@gmail.com";
  };

  const replaceMobileNumbers = (mobile = "") => {
    if (mobile.length === 10)
      return mobile.substring(0, 4) + "****" + mobile.substring(8);
    else return mobile || "0000****00";
  };

  const submitOtpDetails = (e) => {
    e.preventDefault();
    if (emailOTPNumber.length < 6 || mobileOTPNumber < 6) {
      setIsInvalidOTP(true);
    } else {
      confirmEmailMobileOtp();
    }
  };

  const confirmEmailMobileOtp = async () => {
    const otpDetails = {
      emailId: memberDetails.emailAddress,
      emailOTPNumber: emailOTPNumber,
      mobileNumber: memberDetails.mobileNumber,
      mobileOTPNumber: mobileOTPNumber,
    };
    try {
      setLoading(true);
      const response = await userService.confirmRegistration(otpDetails);
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        setShowModal(true);
      } else {
        setIsInvalidOTP(true);
        setApiError(detail);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetErrors = () => {
    setIsInvalidOTP(false);
    setApiError("");
  };

  const navigateToLoginPage = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <div>
      <form
        onSubmit={submitOtpDetails}
        className={`registration-input-wrapper error-boundary ${
          isInvalidOTP ? "error" : ""
        }`}
      >
        <h4 className="welcome-text text-center">Welcome to Boozno</h4>
        <h3 className="mb-2 otp-title text-center">Enter OTP</h3>
        <p className="description mb-5 fs-14-px text-center">
          OTP sent on your email and mobile number
        </p>
        <div>
          <label className="custom-label mb-3">
            Email OTP sent on {replaceEmailChars(memberDetails?.emailAddress)}
          </label>
          <OtpInputs onChange={setEmailOTP} isInvalid={isInvalidOTP} />
          <label className="custom-label mt-2 mb-3">
            Mobile OTP sent on{" "}
            {replaceMobileNumbers(memberDetails?.mobileNumber)}
          </label>
          <OtpInputs onChange={setMobileOTP} isInvalid={isInvalidOTP} />
        </div>
        {apiError && (
          <label className="mb-3 input-error-text">{apiError}</label>
        )}
        <button type="submit" className="btn btn-primary w-100">
          {loading && <div className="spinner-border spinner-border-sm me-1" />}
          Confirm OTP
        </button>
      </form>
      <ResendRegistrationOtp
        resetErrors={resetErrors}
        registrationDetails={{
          firstName: "",
          middleName: "",
          lastName: "",
          countryCode: "",
          mobileNumber: memberDetails?.mobileNumber,
          emailID: memberDetails?.emailAddress,
          gstNumber: "string",
          companyName: "",
          entityName: "",
          addressLineOne: "",
          addressLineTwo: "",
          countryId: 0,
          stateId: 0,
          cityId: 0,
          zipCode: "",
        }}
      />
      {showModal && (
        <VerificationSuccessModal
          show={showModal}
          setShow={setShowModal}
          onConfirm={navigateToLoginPage}
        />
      )}
    </div>
  );
};

export default MemberVerificationOtp;
