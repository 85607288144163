import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useOutletContext } from "react-router-dom";
import TermAndConditionModal from "../common/TermAndConditionModal";
import userService from "../../../services/api-services/userServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";

const MemberVerifyForm = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(false);
  const [termAndConditionModal, setTermAndConditionModal] = useState(false);
  const { memberDetails } = useOutletContext();

  const validate = () => {
    let errorList = {};
    let valid = true;
    if (!termAndCondition) {
      errorList.termAndCondition = "Please agree to terms and conditions";
      valid = false;
    }
    setErrors(errorList);
    return valid;
  };

  const verifyMemberDetails = async () => {
    if (validate()) {
      try {
        setIsLoading(true);
        const response = await userService.sendMemberVerificationOtp(
          memberDetails
        );
        const { detail, message, title } = response;
        if (title === apiStatus.success) {
          navigate("/member-verification/verify-otp-details");
        } else {
          setErrors({ emailOrMobile: detail?.replace(/'/g, "") });
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="text-center">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3 text-nowrap">Verify your Email & Mobile no.</h3>
        <p className="description mb-5">
          Contact admin to change your mobile number or email address.
        </p>
      </div>
      
      <div>
        <label className="form-label">Mobile Number</label>
        <div className="mb-4">{memberDetails?.mobileNumber}</div>
      </div>
      <div>
        <label className="form-label">Email Address</label>
        <div className="mb-4">{memberDetails?.emailAddress}</div>
      </div>

      <div className=" mb-4">
        <div
          className={`error-boundary ${errors.termAndCondition ? "error" : ""}`}
        >
          <input
            type="checkbox"
            className="form-check-input custom-check"
            id="termAndCondition"
            value={termAndCondition}
            checked={termAndCondition}
            onChange={(e) => setTermAndCondition(e.target.checked)}
          />
          <label
            className="form-check-label ms-2 text-black fs-14"
            htmlFor="termAndCondition"
          >
            I agree to the
          </label>
          <span
            role="button"
            className="txt-primary ms-1 fs-14"
            onClick={() => setTermAndConditionModal(true)}
          >
            Terms & Conditions
          </span>
          {errors.termAndCondition && (
            <div className="input-error-text">{errors.termAndCondition}</div>
          )}
        </div>
      </div>

      <button
        onClick={verifyMemberDetails}
        className="btn btn-primary w-100 mb-4"
      >
        {isLoading && <Spinner animation="border" size="sm" />}
        <span className="ms-1">Send OTP</span>
      </button>
      <TermAndConditionModal
        onConfirmClick={() => setTermAndCondition(true)}
        show={termAndConditionModal}
        handleClose={() => setTermAndConditionModal(false)}
      />
    </div>
  );
};

export default MemberVerifyForm;
