import React, { useEffect, useRef, useState } from "react";
import "./OtpInputs.scss";

const OtpInputs = ({ onChange, isInvalid }) => {
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  const [otp, setOTP] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    const digits = otp.join("");
    digits && onChange(parseInt(digits));
  }, [otp]);

  const handleChange = (index, e) => {
    const { value } = e.target;
    let data = [...otp];
    data[index] = value;
    setOTP(data);
    const nextIndex = index + 1;
    if (value.length >= 1) {
      inputRefs[index].current.blur();
    }
    if (value && nextIndex < inputRefs.length) {
      inputRefs[nextIndex].current.select();
      inputRefs[nextIndex].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    const { value } = e.target;
    if (e.key === "Backspace") {
      const prevIndex = index - 1;
      if (!value && prevIndex >= 0) {
        inputRefs[prevIndex].current.select();
        inputRefs[prevIndex].current.focus();
      }
    } else if (e.key === "ArrowLeft") {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        inputRefs[prevIndex].current.select();
        inputRefs[prevIndex].current.focus();
      }
    } else if (e.key === "ArrowRight") {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[nextIndex].current.select();
        inputRefs[nextIndex].current.focus();
      }
    }
  };

  const handleInputFocus = (index, e) => {
    inputRefs[index].current.select();
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === inputRefs.length) {
      const data = pasteData.split("").map((char, i) => {
        inputRefs[i].current.value = char;
        return char;
      });
      setOTP(data);
      inputRefs[inputRefs.length - 1].current.focus();
    }
    e.preventDefault();
  };

  useEffect(() => {
    const firstInput = inputRefs[0].current;
    firstInput.addEventListener("paste", handlePaste);
    return () => {
      firstInput.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <div
      className={`mx-0 mb-4 otp-inputs-container ${isInvalid ? "invalid" : ""}`}
    >
      {otp.map((digit, index) => (
        <div key={index}>
          <input
            className="form-control"
            ref={inputRefs[index]}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onFocus={(e) => handleInputFocus(index, e)}
          />
        </div>
      ))}
    </div>
  );
};

export default OtpInputs;
