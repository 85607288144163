import axiosInstance from "../axiosInstance";
import { endPointsUrls } from "../endPointsUrls";

const commonServices = {
  getAllCountryList: () => {
    return axiosInstance.get(endPointsUrls.country);
  },
  getAllStateList: (countryId) => {
    return axiosInstance.get(endPointsUrls.state + `?CountryId=${countryId}`);
  },
  getAllCityList: (stateId) => {
    return axiosInstance.get(endPointsUrls.city + `?StateId=${stateId}`);
  },
  getSourceList: () => {
    return axiosInstance.get(endPointsUrls.getSourceList);
  },
  getPersonalPurposeList: () => {
    return axiosInstance.get(endPointsUrls.getPersonalPurpose);
  },
  getCorporatePurposeList: () => {
    return axiosInstance.get(endPointsUrls.getCorporatePurpose);
  },
};

export default commonServices;
