import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import LeftDesign from "../common/LeftDesign/LeftDesign";
import "./Registration.scss";
import Cookies from "js-cookie";

const RegistrationParent = () => {
  const [registrationDetails, setRegistrationDetails] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    countryCode: "+91",
    gstNumber: "",
    companyName: "",
    entityName: "",
    addressLineOne: "",
    addressLineTwo: "",
    zipCode: "",
    emailId: "",
    countryId: "",
    cityId: "",
    stateId: "",
  });

  const [loggedIn] = useState(Cookies.get("token"));

  if (loggedIn) return <Navigate to="/dashboard" />;

  return (
    <div className="registration-page-container">
      <div className="row h-100 m-0">
        <div className="col-md-5 col-sm-12 p-2 p-md-4">
          {/* this component will render the left side design in signUp page */}
          <LeftDesign />
        </div>
        <div className="col-md-7 col-sm-12">
          <div className="registration-input-wrapper-container">
            <div className="registration-input-wrapper">
              <Outlet
                context={{ registrationDetails, setRegistrationDetails }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationParent;
