import React, { useEffect, useState } from "react";
import OtpInputs from "../common/InputOtp/OtpInputs";
import { useNavigate, useOutletContext } from "react-router-dom";
import RegistrationStepper from "./RegistrationStepper";
import { onConfirmRefresh } from "../../../utils/JSutils";
import AccountCreatedModal from "./AccountCreatedModal";
import { toast } from "react-toastify";
import apiStatus from "../../../utils/apiResponseHandler";
import userService from "../../../services/api-services/userServices";
import { trimObjectValues } from "../../../utils/JSutils";
import ResendRegistrationOtp from "./ResendRegistrationOtp";

const CreateEnterOtpMobileEmail = () => {
  const navigate = useNavigate();
  const { registrationDetails, setRegistrationDetails } = useOutletContext();
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [emailOTPNumber, setEmailOTP] = useState("");
  const [mobileOTPNumber, setMobileOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    if (!registrationDetails.emailId) {
      navigate("/register");
    }

    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    let timeout = "";
    if (showModal) {
      timeout = setTimeout(() => {
        setShowModal(false);
        navigate("/");
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [showModal]);

  const replaceEmailChars = (email = "") => {
    let [first, last] = email.split("@");
    if (first.length > 4) {
      const center = Math.floor((first.length - 4) / 2);
      return (
        first.substring(0, center) +
        "****" +
        first.substring(center + 4) +
        "@" +
        last
      );
    } else return email || "user.email@gmail.com";
  };

  const replaceMobileNumbers = (mobile = "") => {
    if (mobile.length === 10)
      return mobile.substring(0, 4) + "****" + mobile.substring(8);
    else return mobile || "0000****00";
  };

  const submitOtpDetails = (e) => {
    e.preventDefault();
    if (emailOTPNumber.length < 6 || mobileOTPNumber < 6) {
      setIsInvalidOTP(true);
    } else {
      confirmEmailMobileOtp();
    }
  };

  const confirmEmailMobileOtp = async () => {
    const userData = trimObjectValues(registrationDetails);
    const otpDetails = {
      emailId: userData.emailId,
      emailOTPNumber: emailOTPNumber,
      mobileNumber: userData.mobileNumber,
      mobileOTPNumber: mobileOTPNumber,
    };
    try {
      const response = await userService.confirmRegistration(otpDetails);
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        setShowModal(true);
      } else {
        setIsInvalidOTP(true);
        setApiError(detail);
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetErrors = () => {
    setIsInvalidOTP(false);
    setApiError("");
  };

  return (
    <div>
      <RegistrationStepper
        personal="filled"
        companyDetails="filled"
        otpVerify="active"
      />
      <form
        onSubmit={submitOtpDetails}
        className={`registration-input-wrapper error-boundary ${
          isInvalidOTP ? "error" : ""
        }`}
      >
        <h3 className="mb-2 otp-title">Enter OTP</h3>
        <p className="description mb-5 fs-14-px">
          OTP sent on your email and mobile number
        </p>
        <div>
          <label className="custom-label mb-3">
            Email OTP sent on {replaceEmailChars(registrationDetails.emailId)}
          </label>
          <OtpInputs onChange={setEmailOTP} isInvalid={isInvalidOTP} />
          <label className="custom-label mt-2 mb-3">
            Mobile OTP sent on{" "}
            {replaceMobileNumbers(registrationDetails.mobileNumber)}
          </label>
          <OtpInputs onChange={setMobileOTP} isInvalid={isInvalidOTP} />
        </div>
        {apiError && (
          <label className="mb-3 input-error-text">{apiError}</label>
        )}
        <button type="submit" className="btn btn-primary w-100">
          {loading && <div className="spinner-border spinner-border-sm me-1" />}
          Confirm OTP
        </button>
      </form>
      <ResendRegistrationOtp resetErrors={resetErrors} registrationDetails={registrationDetails} />
      <AccountCreatedModal show={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default CreateEnterOtpMobileEmail;
