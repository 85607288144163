import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { emailRegex, mobileRegex } from "../../../utils/Regex";
import userService from "../../../services/api-services/userServices";
import Spinner from "react-bootstrap/Spinner";

const ResendLoginOtp = ({ emailOrMobile, setErrors }) => {
  const [timer, setTimer] = useState(60);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timer);
    }
  }, [timer]);

  const resendOtpClick = async () => {
    try {
      let response = {};
      setIsLoading(true);
      if (emailRegex.test(emailOrMobile)) {
        response = await userService.loginByEmail(emailOrMobile);
      }
      if (mobileRegex.test(emailOrMobile)) {
        response = await userService.loginByMobile(emailOrMobile);
      }

      const { detail, data, message } = response;
      if (data === true) {
        setErrors("");
        setTimer(60);
        // toast.success(detail);
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="resend-otp-wrapper">
      {timer > 0 ? (
        <>
          <label className="form-label">Resend OTP in </label> 00:
          {timer < 10 ? `0${timer}` : timer}
        </>
      ) : (
        <button className="resend-otp-btn" onClick={resendOtpClick}>
          {isLoading && <Spinner animation="border" size="sm" />} Resend OTP
        </button>
      )}
    </div>
  );
};

export default ResendLoginOtp;
