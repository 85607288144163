import React from "react";
import img from "../../../assets/img/popup-tick-img.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const VerificationSuccessModal = ({ show, setShow, onConfirm }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Verification Successfully</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="inactive-account-modal-wrapper text-center">
          <img src={img} alt="" />
          <h4 className="fw-bold">Verification Successfully</h4>
          <p className="description mb-3 text-center">
            Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis class
            aptent.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onConfirm}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerificationSuccessModal;
