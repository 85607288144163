import React, { useState,useEffect } from "react";
import { Outlet } from "react-router";
import LeftDesign from "../common/LeftDesign/LeftDesign";
import "./Login.scss";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const LoginParent = () => {
  const [loginDetails, setLoginDetails] = useState({
    emailOrMobile: "",
  });

  useEffect(() => {
    document.title = "Boozno-Venue";
  }, []);

  const [loggedIn] = useState(Cookies.get("token"));

  if (loggedIn) return <Navigate to="/dashboard" />;

 
  return (
    <div className="login-page-container">
      <div className="row h-100 m-0">
        <div className="col-md-5 col-sm-12 p-2 p-md-4">
          {/* this component will render the left side design in signUp page */}
          <LeftDesign />
        </div>
        <div className="col-md-7 col-sm-12">
          <div className="login-input-wrapper-container">
            <div className="login-input-wrapper">
              <Outlet context={{ loginDetails, setLoginDetails }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginParent;
