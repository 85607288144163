import React, {
  createContext,
  useEffect,
  useState,
  Suspense,
  lazy,
} from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
// import LoggedInRoutes from "./LoggedInRoutes";
import "./App.css";
import CreateAccountPersonalDetailsForm from "./components/login-registration/registration/CreateAccountPersonalDetails";
import RegistrationParent from "./components/login-registration/registration/RegistrationParent";
import CreateCompanyDetails from "./components/login-registration/registration/CreateCompanyDetails";
import CreateEnterOtpMobileEmail from "./components/login-registration/registration/CreateEnterOtpMobileEmail";
import LoginParent from "./components/login-registration/login/LoginParent";
import SendOtpForm from "./components/login-registration/login/SendOtpForm";
import VerifyLoginOtp from "./components/login-registration/login/VerifyLoginOtp";
import Cookies from "js-cookie";
import MemberVerification from "./components/login-registration/member-verification/MemberVerification";
import MemberVerifyForm from "./components/login-registration/member-verification/MemberVerifyForm";
import MemberVerificationOtp from "./components/login-registration/member-verification/MemberVerificationOtp";
import SpinnerLoader from "./components/common/spinner/SpinnerLoader";
import apiStatus from "./utils/apiResponseHandler";
import userService from "./services/api-services/userServices";
import dayjs from "dayjs";
const LoggedInRoutes = lazy(() => import("./LoggedInRoutes"));

export const PermissionContext = createContext();
export const ProfileContext = createContext();

function App() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userPermissions, setPermissions] = useState([]);
  const [profileDetails, setProfileDetails] = useState({});

  // useEffect(() => {
  //   if (!loggedIn) navigate("/");
  // }, [loggedIn]);

  useEffect(() => {
    if (loggedIn || Cookies.get("token")) {
      getProfileData();
    }
  }, [loggedIn]);

  const location = useLocation();

  useEffect(() => {
    const titles = [
      {
        path: "/register",
        title: "Boozno-Venue",
      },
      {
        path: "/profile",
        title: "Profile",
      },
      {
        path: "/settings/account-details",
        title: "Settings - Account Details",
      },
      {
        path: "/import",
        title: "Import & Export",
      },
      {
        path: "/reminders",
        title: "Reminders",
      },
      {
        path: "/guest-management",
        title: "Guest Management",
      },
      {
        path: "/inquiry-bookings",
        title: "Inquiries & Bookings",
      },
      {
        path: "/calender",
        title: "Calendar",
      },
      {
        path: "/dashboard",
        title: "Dashboard",
      },
      {
        path: "/settings/user-management",
        title: "Settings - User Management",
      },
      {
        path: "/settings/subscriptions",
        title: "Settings - Subscription",
      },
      {
        path: "/settings/reports",
        title: "Settings - Reports",
      },
      {
        path: "/settings/help-support",
        title: "Settings - Help & Support",
      },
      {
        path: "/",
        title: "Boozno-Venue",
      },
    ];
    let currentPage = titles.find((item) =>
      location.pathname.startsWith(item.path)
    );
    if (currentPage) document.title = currentPage.title;
  }, [location]);

  const getProfileData = async () => {
    try {
      const response = await userService.getProfileDetails();
      const { title, data } = response;
      if (title === apiStatus.success) {
        let updated = {
          ...data,
          profileImage: data.profileImage
            ? `${data.profileImage}?timestamp=${dayjs().valueOf()}`
            : "",
        };
        setProfileDetails(updated);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  

  return (
    <PermissionContext.Provider
      value={{
        userPermissions,
        setPermissions,
      }}
    >
      <ProfileContext.Provider
        value={{
          profileDetails,
          setProfileDetails,
          getProfileData,
        }}
      >
        <Routes>
          {/* Login Route Components */}
          <Route path="/" element={<LoginParent />} >
            <Route index element={<SendOtpForm />} />
            <Route path="login" element={<SendOtpForm />} />
            <Route
              path="login/verify-otp"
              element={<VerifyLoginOtp setLoggedIn={setLoggedIn} />}
            />
          </Route>

          {/* Member verification Route Components */}
          <Route path="/member-verification" element={<MemberVerification />}>
            <Route index element={<MemberVerifyForm />} />
            <Route
              path="verify-otp-details"
              element={<MemberVerificationOtp />}
            />
          </Route>

          {/* Registration Route Components */}
          <Route path="/register" element={<RegistrationParent />}>
            <Route index element={<CreateAccountPersonalDetailsForm />} />
            <Route
              path="personal"
              element={<CreateAccountPersonalDetailsForm />}
            />
            <Route path="company-details" element={<CreateCompanyDetails />} />
            <Route path="verify-otp" element={<CreateEnterOtpMobileEmail />} />
          </Route>

          {/* Logged In Routes */}
          <Route
            path="/*"
            element={
              <Suspense
                fallback={
                  <div className="lazy-load">
                    <SpinnerLoader />
                  </div>
                }
              >
                <LoggedInRoutes
                  profileDetails={profileDetails}
                  setLoggedIn={setLoggedIn}
                />
              </Suspense>
            }
          />
        </Routes>
      </ProfileContext.Provider>
    </PermissionContext.Provider>
  );
}

export default App;
